import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useMouse from '@react-hook/mouse-position';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { GatsbyImage } from 'gatsby-plugin-image';

import Grid from '../../common/Grid';
import UnstyledList from '../../common/UnstyledList';
import { H2, H6 } from '../../common/Typography';
import fluidRange from '../../../utils/fluidRange';
import usePreviousState from '../../../hooks/usePreviousState';
import { fadeIn, fadeInRight, duration } from '../../../utils/transitions';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import useWaypoint from '../../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blueGrey};
  overflow-x: hidden;
`;

const Title = styled(H6)`
  grid-column: col / span 12;
`;

const List = styled(UnstyledList)`
  grid-column: col / span 12;
  @media (hover: none) {
    position: relative;
  }
`;

const Name = styled(H2)`
  white-space: pre;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '65px',
    toSize: '200px',
  })};
  ${maxWidth('mobile')} {
    color: ${({ theme }) => theme.colors.white};
  }
  ${minWidth('mobile')} {
    cursor: default;
    transition: color 0.3s ease;
    color: ${({ active, theme }) =>
      active ? theme.colors.white : theme.colors.darkBlue};
  }
`;

const Client = styled.li`
  display: inline-block;
`;

const Img = styled.div`
  pointer-events: none;
  clip-path: ${({ active }) =>
    active
      ? `polygon(0 0, 100% 0, 100% 100%, 0% 100%)`
      : `polygon(0 0, 0 0, 0 100%, 0% 100%)`};
  transition: -webkit-clip-path ${duration / 2}s ease
    ${({ active, delay }) => (active && delay ? duration / 2 : 0)}s;
  ${fluidRange({
    prop: 'width',
    fromSize: '200px',
    toSize: '380px',
  })};
  ${maxWidth('mobile')} {
    display: none;
  }
  ${minWidth('mobile')} {
    position: fixed !important;
    z-index: 10;
  }
`;

export default function Clients({ className, clients, title }) {
  const [ref, visible] = useWaypoint({ threshold: 0.5 });
  const isBrowser = typeof window !== 'undefined';
  const list = useRef(null);
  let clientX = null;
  let clientY = null;
  if (isBrowser) {
    const mouse = useMouse(list, {
      enterDelay: 60,
      leaveDelay: 120,
    });
    clientX = mouse.clientX;
    clientY = mouse.clientY;
  }
  const [active, setActive] = useState(null);
  const previousActive = usePreviousState(active);

  // Hide on scroll
  useScrollPosition(() => setActive(null));

  return (
    <Wrapper ref={ref} paddedTop paddedBottom className={className}>
      <Title as="h2" css={fadeIn(visible)}>
        {title}
      </Title>
      <List ref={list}>
        {clients.map((client, i) => (
          <Client
            key={i}
            onMouseEnter={() => setActive(i)}
            onMouseLeave={() => setActive(null)}
          >
            <Name as="div" active={i === active}>
              <div css={fadeInRight(visible, 2, i * 0.1)}>
                {client.name}
                {i < clients.length - 1 && ', '}
              </div>
            </Name>
            {isBrowser && (
              <Img
                delay={previousActive !== null}
                active={i === active}
                style={{
                  left: clientX && clientX + 20,
                  top: clientY && clientY + 25,
                }}
              >
                <GatsbyImage
                  alt=""
                  role="presentation"
                  image={client.image.asset.gatsbyImageData}
                />
              </Img>
            )}
          </Client>
        ))}
      </List>
    </Wrapper>
  );
}

Clients.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
