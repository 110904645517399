import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withTheme } from 'styled-components';

import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import Slideshow from '../components/common/Slideshow';
import Hero from '../components/common/Hero';
import Clients from '../components/pages/about/Clients';
import Services from '../components/pages/about/Services';
import Values from '../components/pages/about/Values';
import Team from '../components/pages/about/Team';
import PreFooter from '../components/common/PreFooter';

function About({ data }) {
  const { page } = data;
  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Hero title={page.heroTitle} text={page.heroText} />
      <Slideshow slides={page.slides} />
      <Clients title={page.clientsTitle} clients={page.clients} />
      <Services
        title={page.servicesTitle}
        image={page.servicesImage}
        services={page.services}
      />
      <Values title={page.valuesTitle} values={page.values} />
      <Team title={page.teamMembersTitle} members={page.teamMembers} />
      <PreFooter />
    </>
  );
}

export default localize(withTheme(About));

export const query = graphql`
  query {
    page: sanityAbout {
      title {
        localized
      }
      description {
        localized
      }
      heroTitle {
        localized
      }
      heroText {
        localized
      }
      slides {
        image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1440
              height: 640
              placeholder: BLURRED
            )
          }
        }
        alt {
          localized
        }
      }
      clientsTitle {
        localized
      }
      clients {
        name
        image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 420
              height: 280
              placeholder: BLURRED
            )
          }
        }
      }
      servicesTitle {
        localized
      }
      servicesImage {
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        alt {
          localized
        }
      }
      services {
        localized
      }
      valuesTitle {
        localized
      }
      values {
        _key
        title {
          localized
        }
        text {
          localized
        }
      }
      teamMembersTitle {
        localized
      }
      teamMembers {
        id
        name
        role {
          localized
        }
        image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 700
              height: 476
              placeholder: BLURRED
            )
          }
        }
        biography {
          localized
        }
        externalLinks {
          _key
          name
          url
        }
      }
    }
  }
`;

About.propTypes = {
  data: PropTypes.object.isRequired,
};
