import React, { createRef, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Waypoint } from 'react-waypoint';

import { H2, H5, H6, P } from '../../common/Typography';
import ArrowLink from '../../common/ArrowLink';
import Grid from '../../common/Grid';
import UnstyledButton from '../../common/UnstyledButton';
import UnstyledList from '../../common/UnstyledList';
import fluidRange from '../../../utils/fluidRange';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { isSafari } from '../../../utils/browserDetection';
import useWindowSize from '../../../hooks/useWindowSize';
import useScrollListener from '../../../hooks/useScrollListener';
import { duration, fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import Unclip from '../../common/Unclip';

const topOffset = 40;

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.blueGrey};
  background: ${({ theme }) => theme.colors.offWhite};
  align-items: flex-start;
  scroll-behavior: smooth;
`;

const Title = styled(H6)`
  grid-column: col / span 12;
  ${minWidth('tabletPortrait')} {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
`;

const ListWrapper = styled.div`
  ${maxWidth('tabletPortrait')} {
    grid-column: full-bleed;
    overflow-x: scroll;
    display: inherit;
    grid-template-columns: inherit;
    grid-column-gap: inherit;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 5vw;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 6;
  }
`;

const List = styled(UnstyledList)`
  display: grid;
  ${maxWidth('tabletPortrait')} {
    align-items: start;
    grid-column: col;
    grid-column-gap: 5vw;
    grid-template-columns: repeat(${({ mobileCols }) => mobileCols - 1}, 80vw) 85vw;
  }
  ${minWidth('tabletPortrait')} {
    padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
    ${fluidRange({
      prop: 'grid-row-gap',
      fromSize: '40px',
      toSize: '80px',
    })};
  }
`;

const StickyWrapper = styled.div`
  grid-column: col 8 / span 5;
  top: ${topOffset}px;
  position: sticky;
  ${maxWidth('tabletPortrait')} {
    display: none;
  }
  ${minWidth('tabletPortrait')} {
    display: grid;
  }
`;

const About = styled.div`
  display: grid;
  grid-row-gap: 20px;
  top: 0;
  transition: opacity ${duration / 2}s ease;
  grid-row: 2;
  grid-column: 1;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
`;

const Hgroup = styled(UnstyledButton)`
  ${maxWidth('tabletPortrait')} {
    pointer-events: none;
  }
`;

const Member = styled.li`
  ${maxWidth('tabletPortrait')} {
    display: grid;
    grid-row-gap: 20px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    &:last-child {
      padding-right: 5vw;
    }
  }
  ${minWidth('tabletPortrait')} {
    > *:not(${Hgroup}) {
      display: none;
    }
    &:not(:first-child) {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }
`;

const Name = styled(H2)`
  ${fluidRange({
    prop: 'font-size',
    fromSize: '45px',
    toSize: '220px',
  })};
  ${maxWidth('tabletPortrait')} {
    color: ${({ theme }) => theme.colors.blueGrey};
  }
  ${minWidth('tabletPortrait')} {
    transition: color ${duration / 2}s ease;
    color: ${({ active, theme }) =>
      active ? theme.colors.blueGrey : theme.colors.greyLight};
  }
`;

const Portrait = styled(GatsbyImage)`
  ${maxWidth('tabletPortrait')} {
    height: 25vh;
  }
`;

const ExternalLinks = styled.nav`
  display: inline-grid;
  justify-content: start;
  ${maxWidth('tabletPortrait')} {
    grid-auto-flow: row;
  }
  ${minWidth('tabletPortrait')} {
    grid-auto-flow: column;
    grid-gap: 20px;
  }
`;

const Role = styled(H5)`
  color: ${({ theme }) => theme.colors.greyLight};
`;

export default function Team({ className, members, title }) {
  const [ref, visible] = useWaypoint({ threshold: 0.25 });
  const windowSize = useWindowSize();
  const [holdingIndex, setHoldingIndex] = useState(0);
  const [activeMember, setActiveMember] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  const createRefPerMember = () => useRef(members.map(() => createRef()));
  const hgroupRefs = createRefPerMember();
  const aboutRefs = createRefPerMember();
  const $firstTitle = hgroupRefs.current[0];
  const $lastAbout = aboutRefs.current[members.length - 1];

  const scrolling = useScrollListener();

  const scollToListItem = (target) =>
    target.scrollIntoView({
      behavior: 'smooth',
    });

  const handleHgroupClick = (e, i) => {
    scollToListItem(e.target);
    setActiveMember(i);
  };

  const onEnter = (e, i) => {
    if (e.previousPosition === 'above') {
      setHoldingIndex(i);
    }
  };

  const onLeave = (e, i) => {
    if (e.currentPosition === 'above' && i < members.length - 1) {
      setHoldingIndex(i + 1);
    }
  };

  // Pad the bottom of the container to allow scrolling for the last member
  useEffect(() => {
    setPaddingBottom(
      $lastAbout.current.offsetHeight - $firstTitle.current.offsetHeight
    );
  }, [windowSize, $firstTitle, $lastAbout]);
  // Wait for the scroll to stop to update active member
  useEffect(() => {
    if (!scrolling) {
      setActiveMember(holdingIndex);
    }
  }, [scrolling, holdingIndex]);

  useEffect(() => {
    // HACK: https://stackoverflow.com/questions/64317214/safari-webkit-css-scroll-snap-bug
    if (!isSafari()) {
      Object.assign(document.documentElement.style, {
        'scroll-snap-type': 'y proximity',
        'scroll-padding-top': `${topOffset}px`,
      });
    }
    return () => {
      Object.assign(document.documentElement.style, {
        'scroll-snap-type': 'unset',
        'scroll-padding-top': 'unset',
      });
    };
  });

  return (
    <Wrapper ref={ref} className={className} paddedTop paddedBottom>
      <Title css={fadeIn(visible)} as="h2">
        {title}
      </Title>
      <ListWrapper css={fadeIn(visible)}>
        <List mobileCols={members.length} paddingBottom={paddingBottom}>
          {members.map((member, i) => (
            <Waypoint
              key={member.id}
              onEnter={(e) => onEnter(e, i)}
              onLeave={(e) => onLeave(e, i)}
            >
              <Member active={activeMember === i} ref={hgroupRefs.current[i]}>
                <Portrait
                  image={member.image.asset.gatsbyImageData}
                  alt={member.name}
                  imgStyle={{
                    objectFit: 'cover',
                  }}
                />
                <Hgroup onClick={(e) => handleHgroupClick(e, i)}>
                  <Name active={activeMember === i} as="h3">
                    {member.name}
                  </Name>
                  <Role as="p">{member.role}</Role>
                </Hgroup>
                <P>{member.biography}</P>
                <ExternalLinks>
                  {member.externalLinks.map((externalLink) => (
                    <ArrowLink
                      key={externalLink._key}
                      href={externalLink.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {externalLink.name}
                    </ArrowLink>
                  ))}
                </ExternalLinks>
              </Member>
            </Waypoint>
          ))}
        </List>
      </ListWrapper>
      <StickyWrapper>
        {members.map((member, i) => (
          <About
            active={activeMember === i}
            ref={aboutRefs.current[i]}
            key={member.id}
          >
            <Unclip
              direction="left"
              active={visible && activeMember === i}
              order={5}
            >
              <Portrait
                image={member.image.asset.gatsbyImageData}
                alt={member.name}
              />
            </Unclip>
            <P css={fadeIn(visible && activeMember === i, 5)}>
              {member.biography}
            </P>
            <ExternalLinks css={fadeIn(visible && activeMember === i, 5)}>
              {member.externalLinks.map((externalLink) => (
                <ArrowLink
                  key={externalLink._key}
                  href={externalLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {externalLink.name}
                </ArrowLink>
              ))}
            </ExternalLinks>
          </About>
        ))}
      </StickyWrapper>
    </Wrapper>
  );
}

Team.propTypes = {
  className: PropTypes.string,
  members: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
