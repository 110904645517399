import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import { H4, H6, P } from '../../common/Typography';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import fluidRange from '../../../utils/fluidRange';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  ${minWidth('tabletLandscape')} {
    padding-top: 10vh !important;
  }
  ${fluidRange(
    {
      prop: 'grid-row-gap',
      fromSize: '110px',
      toSize: '190px',
    },
    'tabletLandscape',
    'desktop'
  )}
`;

const Title = styled(H6)`
  grid-column: col / span 12;
`;

const Value = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  ${fluidRange({
    prop: 'grid-row-gap',
    fromSize: '12.5px',
    toSize: '25px',
  })}
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 10;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: span 4;
    &:first-of-type {
      grid-column: col / span 4;
    }
  }
`;

const Text = styled(P)`
  color: ${({ theme }) => theme.colors.lightBlue};
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default function Values({ className, title, values }) {
  const [ref, visible] = useWaypoint();
  return (
    <Wrapper ref={ref} className={className} paddedTop paddedBottom>
      <Title css={fadeIn(visible)} as="h2">
        {title}
      </Title>
      {values.map((value, i) => (
        <Value css={fadeIn(visible, i + 2)} key={value._key}>
          <H4 as="h3">{value.title}</H4>
          <div>
            {value.text.split('\n\n').map((p, textIndex) => (
              <Text key={textIndex}>{p}</Text>
            ))}
          </div>
        </Value>
      ))}
    </Wrapper>
  );
}

Values.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
};
