import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Grid from './Grid';
import UnstyledButton from './UnstyledButton';
import UnstyledList from './UnstyledList';

import { minWidth, maxWidth } from '../../utils/breakpoints';
import { fadeIn } from '../../utils/transitions';
import useWaypoint from '../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  position: relative;
  ${maxWidth('desktop')} {
    background: ${({ theme }) => theme.colors.offWhite};
  }
  ${minWidth('desktop')} {
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.offWhite} 0%,
      ${({ theme }) => theme.colors.offWhite} 62%,
      ${({ theme }) => theme.colors.blueGrey} 62%,
      ${({ theme }) => theme.colors.blueGrey} 100%
    );
  }
`;

const Slider = styled(SwipeableViews)`
  grid-row: 1;
  ${maxWidth('desktop')} {
    grid-column: full-bleed;
  }
  ${minWidth('desktop')} {
    grid-column: col / span 12;
  }
`;

const Slide = styled.div`
  position: relative;
  cursor: ${({ isMouseDown }) => (isMouseDown ? `grabbing` : `grab`)};
  ${maxWidth('tabletPortrait')} {
    height: 50vh;
    min-height: 300px;
    max-height: 500px;
  }
  ${minWidth('tabletPortrait')} {
    max-height: 700px;
    height: 90vh;
  }
  > * {
    pointer-events: none;
  }
`;

const Img = styled(GatsbyImage)`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Pagination = styled(UnstyledList)`
  display: inline-grid;
  grid-auto-flow: column;
  justify-self: center;
  z-index: 10;
  align-self: end;
  grid-column: col / span 12;
  grid-row: 1;
  ${maxWidth('tabletPortrait')} {
    grid-gap: 12px;
    margin-bottom: 20px;
  }
  ${minWidth('tabletPortrait')} {
    grid-gap: 16px;
    margin-bottom: 40px;
  }
`;

const Button = styled(UnstyledButton)`
  border-radius: 50%;
  transition: background 0.3s ease;
  background: ${({ active, theme }) =>
    active ? theme.colors.white : 'rgba(255,255,255,.75)'};
  ${maxWidth('tabletPortrait')} {
    height: 12px;
    width: 12px;
  }
  ${minWidth('tabletPortrait')} {
    height: 16px;
    width: 16px;
  }
`;

export default function Slideshow({ slides }) {
  const [ref, visible] = useWaypoint({ threshold: 0 });
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMouseDown, setMouseDown] = useState(false);
  return (
    <Wrapper ref={ref}>
      <Slider
        css={fadeIn(visible, 4)}
        enableMouseEvents
        index={slideIndex}
        onChangeIndex={(i) => setSlideIndex(i)}
      >
        {slides.map((slide, i) => (
          <Slide
            key={i}
            isMouseDown={isMouseDown}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
          >
            <Img image={slide.image.asset.gatsbyImageData} alt={slide.alt} />
          </Slide>
        ))}
      </Slider>
      <Pagination>
        {slides.map((slide, i) => (
          <Button
            key={i}
            active={i === slideIndex}
            onClick={() => setSlideIndex(i)}
          />
        ))}
      </Pagination>
    </Wrapper>
  );
}

Slideshow.propTypes = {
  slides: PropTypes.array.isRequired,
};
