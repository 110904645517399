import { useEffect, useState } from 'react';

export default function useScrollListener(timeout = 400) {
  const [scrolling, setScrolling] = useState(false);
  const isClient = typeof window === 'object';
  useEffect(() => {
    if (!isClient) {
      return false;
    }

    let isScrolling;

    const handleScroll = () => {
      // Clear our timeout throughout the scroll
      clearTimeout(isScrolling);

      setScrolling(true);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => {
        setScrolling(false);
      }, timeout);
    };

    window.addEventListener('scroll', handleScroll, false);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolling, isClient, timeout]);
  return scrolling;
}
