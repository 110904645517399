import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Grid from '../../common/Grid';
import UnstyledList from '../../common/UnstyledList';
import { H5, H6 } from '../../common/Typography';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import Unclip from '../../common/Unclip';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blueGrey};
`;

const Title = styled(H6)`
  grid-column: col / span 12;
`;

const ImageWrapper = styled.figure`
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col / span 5;
  }
`;

const List = styled(UnstyledList)`
  ${maxWidth('mobile')} {
    grid-column: col / span 12;
  }
  ${minWidth('mobile')} {
    grid-column: col / span 12;
    column-gap: 2.5%;
    columns: 2;
  }
  ${minWidth('tabletPortrait')} {
    columns: 3;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 7 / span 6;
    columns: 2;
    align-self: center;
    white-space: nowrap;
  }
`;

export default function Services({ className, image, title, services }) {
  const [ref, visible] = useWaypoint();
  return (
    <Wrapper ref={ref} className={className} paddedBottom>
      <Title as="h2" css={fadeIn(visible)}>
        {title}
      </Title>
      <ImageWrapper>
        <Unclip direction="left" active={visible}>
          <GatsbyImage
            image={image.image.asset.gatsbyImageData}
            alt={image.alt}
          />
        </Unclip>
      </ImageWrapper>
      <List>
        {services.map((service, i) => (
          <H5
            key={i}
            as="li"
            css={fadeIn(visible)}
            style={{ transitionDelay: `${i * 0.1}s` }}
          >
            {service}
          </H5>
        ))}
      </List>
    </Wrapper>
  );
}

Services.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
};
